@font-face {
font-family: '__openSans_420557';
src: url(/_next/static/media/ec2563875dcf6d6c-s.p.woff2) format('woff2');
font-display: swap;
font-style: normal;
}

@font-face {
font-family: '__openSans_420557';
src: url(/_next/static/media/b8eef19e7ff543fb-s.p.woff2) format('woff2');
font-display: swap;
font-style: italic;
}@font-face {font-family: '__openSans_Fallback_420557';src: local("Arial");ascent-override: 101.58%;descent-override: 27.84%;line-gap-override: 0.00%;size-adjust: 105.22%
}.__className_420557 {font-family: '__openSans_420557', '__openSans_Fallback_420557'
}.__variable_420557 {--font-open-sans: '__openSans_420557', '__openSans_Fallback_420557'
}

